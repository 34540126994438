import { useEffect, useState } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { NextFetch } from 'modules/next-fetch';

const SwaggerPage = () => {
    const [swaggerSpec, setSwaggerSpec] = useState(null);

    useEffect(() => {
        const nextFetch = new NextFetch().get('documentation');
        nextFetch
            .exec()
            .then((response) => {
                setSwaggerSpec(response);
            })
            .catch((error) => {
                console.error('Failed to load Swagger documentation:', error);
            });
    }, []);

    if (!swaggerSpec) return <p>Loading...</p>;

    return <SwaggerUI spec={swaggerSpec} displayOperationId={true} />;
};

export default SwaggerPage;
