import { useContext, useState } from 'react';
import { Fields, Field } from 'modules/react-fields-context';
import { IconPencilEdit, IconTrash } from '_dash/components/Icon';
import styled from 'styled-components';
import variables from 'scss/base/_variables.module.scss';
import { apiFetch } from '../util';
import { DocumentsContext } from '_dash/Contexts';

export const FolderItemSt = styled.div`
    display: flex;
    align-items: flex-start;
    position: relative;
    //margin-right: 5px;
    &.active {
        font-weight: 600;
        color: ${variables.colorPrimary};
    }
    &.isChild {
        padding-top: 7px;
        padding-left: 18px;
        &:before {
            content: '';
            position: absolute;
            top: 17px;
            left: 5px;
            width: 10px;
            height: 1px;
            background-color: ${variables.colorBorder};
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 5px;
            width: 1px;
            height: 17px;
            background-color: ${variables.colorBorder};
        }
        &.isEdit {
            &:before,
            &:after {
                display: none;
            }
            .form-group {
                &:before {
                    content: '';
                    position: absolute;
                    top: 20px;
                    left: 5px;
                    width: 10px;
                    height: 1px;
                    background-color: ${variables.colorBorder};
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 5px;
                    width: 1px;
                    height: 20px;
                    background-color: ${variables.colorBorder};
                }
            }
        }
    }
    .edit-wrap {
        width: 100%;
        .btn {
            margin: 5px 0 5px 10px;
            display: block;
            padding: 5px 7px;
            //float: right;
            position: absolute;
            bottom: 0;
            right: 75px;
            &.btn-cancel {
                right: 0;
            }
        }
        .form-group {
            padding-bottom: 40px;
        }
        .form-control {
            padding: 5px 7px;
        }
        .bottom-cta {
            border-top: none;
        }
    }
    .cta-edit {
        margin-left: auto;
        display: flex;
        flex-flow: row nowrap;
        button {
            color: ${variables.colorPrimary};
            padding-left: 8px;
            &.cta-delete {
                svg {
                    fill: ${variables.colorRed};
                    opacity: 0.9;
                }
                &:hover {
                    svg {
                        fill: ${variables.colorRed};
                        opacity: 1;
                    }
                }
            }
            &:hover {
                svg {
                    fill: ${variables.colorSecondary};
                }
                i {
                    color: ${variables.colorSecondary};
                }
            }
            i {
                vertical-align: middle;
            }
        }
    }
`;

export default function DocumentsEditFolder({ elem, isEditMode, handleAddFolder }) {
    const { fetchDocs, docs, setActiveFolderId, activeFolderId, addedFolderId } = useContext(DocumentsContext);
    const [isEdit, setIsEdit] = useState(addedFolderId === elem.id);

    const handleSubmit = async (data, doneSubmit) => {
        await apiFetch({ action: 'api.dash.employer.company.document.editFolder', id: elem.id, name: data.name });
        await fetchDocs();
        setIsEdit(false);
    };
    const handleDeleteFolder = async (folder) => {
        // check if folder is empty 1st
        if (folder.docs.length > 0) {
            window.swal({ type: 'warning', text: 'Only empty folders can be deleted! Please delete all filles first!' });
        } else if (!folder.parentId && docs.filter((d) => d.parentId === folder.id).length > 0) {
            window.swal({ type: 'warning', text: 'Only empty folders can be deleted! Please delete all subfolders first!' });
        } else {
            window.swal({
                type: 'warning',
                text: 'Are you sure?',
                onConfirm: async (close) => {
                    await apiFetch({ action: 'api.dash.employer.company.document.deleteFolder', id: folder.id });
                    await fetchDocs();
                    close();
                    setActiveFolderId(docs[0].id);
                },
            });
        }
    };
    return isEdit ? (
        <FolderItemSt className={'isEdit ' + (!handleAddFolder ? 'isChild' : '')}>
            <div className="edit-wrap">
                <Fields onSubmit={handleSubmit} initialValues={{ name: elem.name }}>
                    <Field
                        name="name"
                        className="mb-0"
                        schema={{
                            type: 'text',
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <button className="btn btn-trs btn-cancel" onClick={() => setIsEdit(false)}>
                        Cancel
                    </button>
                </Fields>
            </div>
        </FolderItemSt>
    ) : (
        <FolderItemSt className={(!handleAddFolder ? 'isChild' : '') + (activeFolderId === elem.id ? ' active' : '')} onClick={() => setActiveFolderId(elem.id)}>
            {elem.name}
            {isEditMode === true && (
                <span className="cta-edit">
                    {handleAddFolder && (
                        <button className="tooltip" data-tooltip={'Add subfolder'} onClick={() => handleAddFolder(elem.id)}>
                            <i className="fas fa-plus" />
                        </button>
                    )}
                    <button className="tooltip" data-tooltip={'Edit'} onClick={() => setIsEdit(true)}>
                        <IconPencilEdit />
                    </button>
                    {elem.id !== docs[0].id && (
                        <button className="cta-delete pr-0 tooltip" data-tooltip={'Delete'} onClick={() => handleDeleteFolder(elem)}>
                            <IconTrash />
                        </button>
                    )}
                </span>
            )}
        </FolderItemSt>
    );
}
