import React from 'react';
import PropTypes from 'prop-types';
import './SelectInput.scss';
import classnames from 'classnames';
// import {eventDelay} from '_dash/components/util';
import { apiFetch, innerText } from '_dash/components/util';
import debounce from 'modules/debounce';

const debounced = debounce(() => new Promise((resolve) => resolve()), 500);
const dataInitState = {
    displayList: [], // used to display data in drop down list
    index: 0, // set to first index  in displayList
    inputValue: '', // populate when you start write in input
    prevInputValue: null, // previous input value
    isFetching: false,
    showList: false, // show/hide drop down list
};

export default class SelectInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // selectedValues: this.updateStateSelectedValue(props), //populated from props.value and is used for  multiple values
            selectedValues: [],
            ...dataInitState,
        };
        this.listRef = React.createRef(); //used to handle event scrollIntoView
        this.inputRef = React.createRef(); // used to handle the event focus
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.updateStateSelectedValue = this.updateStateSelectedValue.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            // this.setState({
            // 	selectedValues: this.updateStateSelectedValue(this.props) //populated from props.value and is used for  mutiple values
            // });
            this.updateStateSelectedValue(this.props);
        }
    }

    componentDidMount() {
        this.updateStateSelectedValue(this.props);
    }

    async updateStateSelectedValue(props) {
        let selectedValues = []; // populate from props
        if (props.isMultiple && Array.isArray(props.value) && props.value.length > 0 && props.value[0].val !== undefined) {
            selectedValues = props.value;
        } else if (props.isMultiple && props.data) {
            if (typeof props.value == 'string' && props.value.length > 0) {
                // convert string to array
                selectedValues = props.value.split(',');
                // convert to array of numbers if is the case
                if (!isNaN(selectedValues[0])) selectedValues = selectedValues.map(Number);
            } else if (typeof props.value == 'object' && props.value.length > 0) {
                //array is an object
                selectedValues = props.value;
            }
            // convert the array to [{val,label}...]
            selectedValues = selectedValues.map((eSelected) => {
                if (props.customKey !== undefined) {
                    let update = Object.assign({}, eSelected);
                    update.val = update[props.customKey];
                    update.label = (() => {
                        const dataFind = props.data.filter((eData) => update[props.customKey] === eData[props.customKey]);
                        if (dataFind.length > 0) {
                            return dataFind[0].label;
                        } else {
                            return update[props.customKey];
                        }
                    })();
                    return update;
                } else {
                    return {
                        val: eSelected,
                        label: (() => {
                            const dataFind = props.data.filter((eData) => eSelected === eData.val);
                            if (dataFind.length > 0) {
                                return dataFind[0].label;
                            } else {
                                return eSelected;
                            }
                        })(),
                    };
                }
            });
        } else if (props.isMultiple && props.urlFetch && props.value && props.value.length > 0) {
            selectedValues = await doFetchIds({ action: props.urlFetch, ids: props.value });
        }
        if (props.isMultiple === false) {
            selectedValues = props.data.filter((eData) => props.value === eData.val);
            if (selectedValues.length === 1) selectedValues = selectedValues[0].val;
        }
        this.setState({ selectedValues: selectedValues });
    }

    // manage some keywords like(Enter,ArrowUp,ArrowDown,esc)
    handleKeyUp(evt) {
        // if (window.devMode && this.props.debug) window.console.log('SelectInput/handleKeyUp.evt', evt); // @debug
        const { index, displayList } = this.state;

        switch (evt.keyCode) {
            case 13:
                evt.preventDefault();
                evt.stopPropagation();
                // Enter: pick current item
                if (displayList[index]) {
                    //if the dropdown is not open then 'displayList[index]' is undefined
                    this.updateSelectVal(!this.props.customKey ? displayList[index].val : displayList[index]);
                }
                break;
            case 38: // ArrowUp: move up if not first
                evt.preventDefault();
                evt.stopPropagation();
                if (index > 0) {
                    this.listRef.current.children[index - 1].scrollIntoView(false);
                    this.setState({
                        index: index - 1,
                    });
                }
                break;
            case 40: // ArrowDown: move down if not last
                evt.preventDefault();
                evt.stopPropagation();
                if (index < displayList.length - 1) {
                    this.listRef.current.children[index + 1].scrollIntoView(false);
                    this.setState({
                        index: index + 1,
                    });
                }
                break;
            case 27: //esc
                evt.preventDefault();
                evt.stopPropagation();
                this.handleReset();
                break;

            default:
                break;
        }
    }

    // update inputValue and displayList from where we choose an option
    async handleOnChange(e) {
        // if (window.devMode && this.props.debug) window.console.log('SelectInput/handleOnChange.e', e.target.value, this.state.inputValue); // @debug
        // e.preventDefault();
        // e.stopPropagation();
        const { data, urlFetch, minLength, maxLength, isMultiple, allowAddOption } = this.props;

        // eventDelay( (()=>{ // used because one time was killed my CPU this event
        let dataUpdate = { inputValue: e.target.value, displayList: [], prevInputValue: this.state.inputValue.slice(0) }; //update inputValue
        // let dataUpdate  = {inputValue:this.inputRef.current.value}; //update inputValue

        if (typeof data !== 'undefined') {
            if (dataUpdate.inputValue.length >= minLength && (maxLength === undefined || dataUpdate.inputValue.length <= maxLength)) {
                //update display list if minLength is passed
                //stript special chars to avoid getting errors
                const regexLocal = new RegExp(dataUpdate.inputValue.replace(/[^0-9a-zA-Z_ -]/gi, ''), 'i');
                //show a displayList that match the input and skip value(s) selected
                dataUpdate['displayList'] = this.updateStateDisplayList(data, isMultiple, regexLocal);

                // insert on first position in list and check if not used already
                if (allowAddOption && !this.state.selectedValues.find((e) => e.val === dataUpdate.inputValue)) {
                    dataUpdate.displayList.unshift({
                        val: dataUpdate.inputValue,
                        label: dataUpdate.inputValue,
                    });
                }
            } else if (dataUpdate.inputValue.length === 0 && minLength === 0) {
                // reach this case when use backspace,delete..
                dataUpdate['displayList'] = this.updateStateDisplayList(data, isMultiple);
            }
            //end process , update the state
        } else if (typeof urlFetch !== 'undefined') {
            // update displayList using  urlFetch
            // if (dataUpdate.inputValue.length >= minLength) {
            debounced() // run a promise delay
                .then(async () => {
                    //when delay time had passed run it
                    if (dataUpdate.inputValue.length >= minLength && (maxLength === undefined || dataUpdate.inputValue.length <= maxLength)) {
                        const dataFetch = await apiFetch({
                            action: urlFetch,
                            q: dataUpdate.inputValue,
                        }).then((data) => (data.length > 0 ? data.map((e) => ({ val: e[0], label: e[1] })) : []));
                        dataUpdate['displayList'] = this.updateStateDisplayList(dataFetch, isMultiple);
                        if (
                            allowAddOption &&
                            !this.state.selectedValues.find((e) => e.val === dataUpdate.inputValue) &&
                            !dataFetch.find((e) => e.label.toLowerCase() === dataUpdate.inputValue.toLowerCase())
                        ) {
                            dataUpdate.displayList.unshift({
                                val: dataUpdate.inputValue,
                                label: dataUpdate.inputValue,
                            });
                        }
                    }
                    dataUpdate['isFetching'] = false;
                    this.setState(dataUpdate);
                })
                .catch(() => {
                    // when delay time was cancel
                });
            dataUpdate['isFetching'] = true;
            // } else {}
        }

        this.setState(dataUpdate);
    }

    //update state.displayList
    updateStateDisplayList(data, isMultiple, regex = false) {
        return data.filter((e) => {
            //e = {val:'string|number',label:'string'}
            let isValueSelected = false;
            if (isMultiple) {
                //check if we should hide items from displayList
                isValueSelected = this.state.selectedValues.find((e2) => e2.val === e.val);
            }
            let text = innerText(e.label);
            // sometimes innerText() can't extras the text and have to provide manual
            if (e.labelString) text = e.labelString;
            return !isValueSelected && (regex ? text.search(regex) > -1 : true);
        });
    }

    // will send selected value/values to props.onChange and reset its state
    async updateSelectVal(val, action = 'add') {
        const { onChange, isMultiple, value } = this.props;
        // send data to props.onChange
        if (isMultiple) {
            //get array data
            let values = this.state.selectedValues.map((e) => {
                if (Object.keys(e).length > 2) {
                    //remove what was added for the selected values
                    e = Object.assign({}, e);
                    delete e.label;
                    delete e.val;
                    return e;
                } else {
                    return e.val;
                }
            });
            if (action === 'add') {
                // add value to array
                if (typeof val === 'object') {
                    val = Object.assign({}, val);
                    delete val.label;
                    delete val.val;
                }
                values.push(val);
            } else {
                // remove value from array
                const index = values.indexOf(val); // get index of value
                values.splice(index, 1); //remove value from array
            }
            //check how we send data: is sent like was received in props.value
            if (typeof value == 'object') {
                // we send data as array
                onChange(values, val, action);
            } else {
                // special case for urlFetch + allowAddOption + new val on update, run the fetch to get the id for the new value
                if (this.props.allowAddOption && this.props.urlFetch && values.filter((v) => typeof v === 'string').length) {
                    // first do the fetch
                    const selectedValues = await doFetchIds({ action: this.props.urlFetch, ids: values.toString() });
                    // parse values
                    values = selectedValues.map((sv) => sv.val);
                }
                // we send data as string
                onChange(values.toString(), val, action);
            }
        } else {
            // for single value
            onChange(val);
        }
        this.handleReset();
    }

    handleReset() {
        this.inputRef.current.blur(); // focus out
        // this.inputRef.current.value = '';//reset inputValue
        this.setState(dataInitState); // reset state to initial values
    }

    handleFocus() {
        // if (window.devMode) window.console.log('handleFocus', this.state, this.props); // @debug
        this.inputRef.current.focus(); // focus the input
        // check if should update the state
        if (!this.state.showList) {
            let stateUpdate = { showList: true }; //show the dropdown
            //check if display on focus displayList
            if (this.props.minLength === 0 && this.props.data !== undefined) {
                stateUpdate['displayList'] = this.updateStateDisplayList(this.props.data, this.props.isMultiple);
            }
            this.setState(stateUpdate);
        }
    }

    handleBlur(e) {
        // e.preventDefault();
        // e.stopPropagation();
        switch (this.props.blurBehavior) {
            case 'save':
                {
                    const { index, displayList, inputValue, prevInputValue } = this.state;
                    // if (window.devMode && this.props.debug) window.console.log('handleBlur/save', {index, displayList, val: displayList[index]}); // @debug
                    // hide list and reset inputValue when loose focus
                    if (displayList[index]) {
                        //if the dropdown is not open then 'displayList[index]' is undefined
                        this.updateSelectVal(!this.props.customKey ? displayList[index].val : displayList[index]);
                    } else if (inputValue.length === 0 && prevInputValue !== null && prevInputValue.length === 1) {
                        // had just deleted the last letter in search input
                        // FIX TO RESET VALUE ON BLUR
                        this.setState({ prevInputValue: null }); // reset to initial displayed value by removing previous value
                    }
                }
                break;

            default:
                // reset
                if (!e.currentTarget.contains(e.relatedTarget)) {
                    this.handleReset();
                }
                break;
        }
    }

    render() {
        const { className, minLength, maxLength, isMultiple, multipleValuesLimit, value, placeholder, allowAddOption, displayOptionsOnFocus } = this.props;

        const { displayList, showList, selectedValues, inputValue, prevInputValue, isFetching } = this.state;

        let updateInputValue = inputValue;
        if (!isMultiple) {
            if (!showList && value) {
                if (allowAddOption) {
                    updateInputValue = value;    
                } else {
                    const filterValue = this.props.data.filter((eData) => value === eData.val);
                    /* if (window.devMode) window.console.log('SelectInput / 340', {
                        propsData: this.props.data, 
                        value, 
                        inputValue, 
                        filterValue: filterValue // [0],
                        // filterValueLabel: filterValue[0].label
                    }); // @debug */
                    if (filterValue.length > 0) {
                        updateInputValue =  filterValue[0].label;
                    }
                }
                // updateInputValue = allowAddOption ? value : this.props.data.filter((eData) => value === eData.val)[0].label;
            } else { // @Mihai this added for case where val == 0 or '' still has element with label ie Application step
                if (value !== undefined) {
                    const filterValue = this.props.data.filter((eData) => value === eData.val);
                    if (!showList && filterValue.length > 0) {
                        updateInputValue =  filterValue[0].label;
                    }
                }
            }
            // if (window.devMode && this.props.debug) window.console.log('SelectInput/render', {value, inputValue, updateInputValue}); // @debug

            // FIX TO KEEP VALUE ON FOCUS
            if (/* showList && */ updateInputValue.length === 0 && value.length > 0 && prevInputValue === null) {
                updateInputValue = value;
            }
        }
        return (
            <div className="modul-react-select-input" tabIndex={0} /*need for event `onBlur`*/ onBlur={this.handleBlur}>
                <div className={classnames('select-control', className)}>
                    {isMultiple && selectedValues.length > 0 && ( //selected multiple values
                        <div className="multiple-select-list">
                            {selectedValues.map((e, index) => {
                                const remove = () => this.updateSelectVal(!this.props.customKey ? e.val : e, 'remove');
                                return this.props.RenderSelected ? (
                                    this.props.RenderSelected({ remove, e, index })
                                ) : (
                                    <div className="multiple-value" key={e.val}>
                                        {e.label}
                                        <span
                                            onClick={remove} //remove one value from the selected multiple values
                                            className="remove-value"
                                        >
                                            <i className="far fa-times mx-1" />
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    <input
                        ref={this.inputRef}
                        value={updateInputValue}
                        placeholder={!isMultiple && value.length > 0 ? (allowAddOption ? value : this.props.data.filter((eData) => value === eData.val)[0].label) : placeholder}
                        onChange={this.handleOnChange}
                        onKeyUp={this.handleKeyUp}
                        onFocus={this.handleFocus}
                        className="form-control"
                        disabled={isMultiple && multipleValuesLimit > 0 && selectedValues.length === multipleValuesLimit}
                        // onBlur={}//can't be used here because can't reach event onClick for option selected
                    />
                </div>
                {isMultiple && multipleValuesLimit > 0 && selectedValues.length === multipleValuesLimit && (
                    <div className="text-info" style={{ display: 'block' }}>
                        <small>You have reached the maximum allowed number of items</small>
                    </div>
                )}
                {showList && (displayOptionsOnFocus || inputValue.length > 0) && (
                    <ul ref={this.listRef} className="allValues">
                        {minLength && inputValue.length < minLength ? (
                            <li className="initial">{minLength} chars is required</li>
                        ) : maxLength && inputValue.length > maxLength ? (
                            <li className="initial">Value too long: max. {maxLength} chars allowed</li>
                        ) : isFetching ? (
                            <li>Loading ...</li>
                        ) : displayList.length === 0 ? (
                            <li>{allowAddOption && isMultiple && this.props.urlFetch ? 'Already added!' : 'No option'}</li>
                        ) : (
                            displayList.map((e, i) => {
                                return (
                                    <li
                                        key={i}
                                        className={this.state.index === i ? 'active' : ''}
                                        onMouseEnter={() => this.setState({ index: i })}
                                        onClick={() => this.updateSelectVal(!this.props.customKey ? e.val : e)}
                                    >
                                        {e.label}
                                    </li>
                                );
                            })
                        )}
                    </ul>
                )}
            </div>
        );
    }
}

SelectInput.propTypes = {
    //will display state.selectedValues(when have isMultiple) or just this value
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(
            PropTypes.shape({
                val: PropTypes.number,
                label: PropTypes.string,
            })
        ),
    ]),
    //will send data back to parent parent component:data = string/listString/arrayString
    onChange: PropTypes.func.isRequired, //will receive the value/values selected
    //will be populated state.displayList
    data: PropTypes.oneOfType([PropTypes.array]), // [{val:'string|number',label:'string'}]
    //will be populated state.displayList
    urlFetch: PropTypes.string, // used to replace props.data the result type must be as props.data, maybe props.value must have label when is used props.isMultiple
    minLength: PropTypes.number, // min chars to start search for items to populate the list
    maxLength: PropTypes.number, // max chars to allow search for items to populate the list
    isMultiple: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    allowAddOption: PropTypes.bool,
    customKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), //to custom populate state.selectedValues[x].val,
    multipleValuesLimit: PropTypes.number,
    blurBehavior: PropTypes.oneOf(['save', 'reset']),
    displayOptionsOnFocus: PropTypes.bool,
    debug: PropTypes.bool,
};

SelectInput.defaultProps = {
    minLength: 0,
    allowAddOption: false,
    placeholder: '',
    multipleValuesLimit: 0,
    blurBehavior: 'reset',
    displayOptionsOnFocus: false,
    debug: false,
    // isMultiple: false, // STRANGE!! this crashes the component at line 337 {selectedValues.map((e) => (
};

async function doFetchIds({ action, ids }) {
    return await apiFetch({ action, ids }).then((data) => (data.length > 0 ? data.map((e) => ({ val: e[0], label: e[1] })) : []));
}
