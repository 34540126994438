import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Dropdown from 'modules/react-dropdown';
import { IconTrash } from '_dash/components/Icon';
import { MultipleUploadSt } from 'modules/react-fields-context/customTypes/MultipleUploadStyle';
import Submit from '../Submit';
import Spinner from 'modules/react-spinner';
import { preLoading } from '_dash/components/util';
import DocumentsProvider from '_dash/components/company/DocumentsProvider';
import MultipleUploadDocsGallery from './MultipleUploadDocsGallery';
import { useSelector } from 'react-redux';

MultipleUpload.propTypes = {
    value: PropTypes.array, // selected options
    onChange: PropTypes.func.isRequired, // update selected values
};
export default function MultipleUpload({
    value = [],
    onChange,
    forwardRef,
    className,
    renderSavedFiles,
    handleDeleteSaved,
    maxFilesCount = 100,
    fileExtensionAccept,
    showSubmit,
    galleryShow = true,
    isDisabled,
    updateHtml,
    downloadFile,
}) {
    const [openGalleryModal, setOpenGalleryModal] = React.useState(false);
    const [isOpenDropdown, setIsOpenDropdown] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { userData } = useSelector((state) => state);

    const handleUpload = (e) => {
        const arrayFilles = [];
        Array.from(e.target.files).forEach((file) => {
            // stop upload empty filles
            if (file.size === 0) {
                window.swal({ type: 'warning', text: file.name + " is empty. You can't upload empty filles!" });
            } else {
                arrayFilles.push(file);
            }
        });
        if (value === undefined || value.length === 0) {
            //1st time when value don't have saved data
            onChange(arrayFilles);
        } else {
            //2nd,3rd ... time when update uploaded filles
            const newData = value.concat(arrayFilles);
            onChange(newData);
        }
    };

    const getUploadedFiles = () => value.filter((f) => f.constructor.name === 'File');
    const getSavedFiles = () => value.filter((f) => f.constructor.name === 'Object');

    const handleDelete = (index) => {
        const newData = getSavedFiles().concat(getUploadedFiles().filter((file, i) => i !== index));
        onChange(newData);
    };
    const updatedSavedData = (savedData, itemDelete) => {
        let newData = savedData.concat(getUploadedFiles());
        if (itemDelete) {
            newData = getSavedFiles()
                .filter((f) => f.id !== itemDelete.id)
                .concat(getUploadedFiles());
        }
        onChange(newData);
    };

    let accept = '*/*';
    if (fileExtensionAccept) {
        accept = fileExtensionAccept.map((ext) => '.' + ext);
        accept = accept.toString();
    }
    if (isLoading) return preLoading();
    return (
        <MultipleUploadSt>
            <div ref={forwardRef}>
                <Dropdown isOpen={isOpenDropdown} updateHtml={updateHtml}>
                    {(handleToggle, isOpen, refOpen) => (
                        <div className={classnames({ show: isOpen && !isDisabled })}>
                            <div className={`attach-icon-box ${isDisabled ? 'isDisabled' : ''}`} onClick={handleToggle}>
                                <span className="icon">
                                    <i className="far fa-paperclip" />
                                    {(value === undefined || value === '') && <sub className="no_item">0</sub>}
                                    {value !== undefined && value !== null && (typeof value === 'object' || value.length > 0) && <sub className="no_item">{value.length}</sub>}
                                </span>
                                <span className="txt">Attach File{maxFilesCount === 1 ? '' : 's'}</span>
                            </div>
                            {isOpen && !isDisabled && (
                                <div ref={refOpen} className="dropdown-menu tooltip-dropdown-menu pos-top">
                                    <span className="arrow" />
                                    <div className="upload-box">
                                        <input
                                            className={classnames(['inputFile', className])}
                                            type="file"
                                            id="uploadFile"
                                            multiple={maxFilesCount === 1 ? false : true}
                                            accept={accept}
                                            onChange={handleUpload}
                                        />
                                        <div className={classnames('inner', { noFile: value.length === 0 })}>
                                            {getUploadedFiles().length > 0 && (
                                                <>
                                                    <h2>Uploaded</h2>
                                                    <ul className="listToAdd">
                                                        {getUploadedFiles().map((file, index) => {
                                                            if (file.constructor.name === 'Object') return null;
                                                            return (
                                                                <li key={index}>
                                                                    <span className="item-name">{file.name}</span>
                                                                    <span className="item-size">
                                                                        (
                                                                        {file.size < 1024 * 1024
                                                                            ? (file.size / 1024).toFixed(2) + ' kb'
                                                                            : (file.size / 1024 / 1024).toFixed(2) + ' mb'}
                                                                        )
                                                                    </span>
                                                                    <span onClick={() => handleDelete(index)} aria-label="delete" className="delete">
                                                                        <i className="fas fa-times"></i>
                                                                    </span>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                    {showSubmit && (
                                                        <Submit
                                                            render={(isLoading, isSaved, submit) => (
                                                                <button
                                                                    className={classnames('btn my-0 mb-1', { 'btn-isLoading': isLoading })}
                                                                    type="button"
                                                                    onClick={submit}
                                                                    disabled={isLoading}
                                                                >
                                                                    {isLoading && <Spinner />}
                                                                    Save
                                                                </button>
                                                            )}
                                                        />
                                                    )}
                                                </>
                                            )}
                                            {getUploadedFiles().length < maxFilesCount && (
                                                <div className="cta-fileAdded">
                                                    <p className="col-12 px-0">Upload File{maxFilesCount === 1 ? '' : 's'} from</p>
                                                    <div className="cta-option">
                                                        <label className="item-add label-inputFile " htmlFor="uploadFile">
                                                            <i className="fas fa-plus" />
                                                            Device
                                                        </label>
                                                        {galleryShow && userData.type === 'employer' && (
                                                            <label
                                                                className="item-add label-inputFile"
                                                                htmlFor="uploadFileGallery"
                                                                onClick={() => {
                                                                    setOpenGalleryModal(true);
                                                                    setIsOpenDropdown(false);
                                                                }}
                                                            >
                                                                <i className="fas fa-plus" />
                                                                Documents
                                                            </label>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {getSavedFiles().length > 0 && renderSavedFiles
                                            ? renderSavedFiles(getSavedFiles(), updatedSavedData)
                                            : handleDeleteSaved && (
                                                  <div className="attach_row">
                                                      <h3>Saved Files</h3>
                                                      <ul>
                                                          {getSavedFiles().map((item) => {
                                                              const attachItemProps = {
                                                                  href: item.file,
                                                              };
                                                              if (item.fileType === 'application/pdf') {
                                                                  attachItemProps.target = '_blank';
                                                                  attachItemProps.rel = 'noopener noreferrer';
                                                              }
                                                              if (downloadFile) attachItemProps.onClick = () => downloadFile(item);

                                                              return (
                                                                  <li key={item.id}>
                                                                      <a className="item-name" {...attachItemProps}>
                                                                          {item.fileName}
                                                                      </a>
                                                                      <span onClick={() => handleDeleteSaved({ item, updatedSavedData })} aria-label="delete" className="delete">
                                                                          <IconTrash />
                                                                      </span>
                                                                  </li>
                                                              );
                                                          })}
                                                      </ul>
                                                  </div>
                                              )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Dropdown>
            </div>
            {openGalleryModal && (
                <DocumentsProvider>
                    <MultipleUploadDocsGallery
                        setOpenGalleryModal={setOpenGalleryModal}
                        handleUpload={handleUpload}
                        setIsOpenDropdown={setIsOpenDropdown}
                        setIsLoading={setIsLoading}
                    />
                </DocumentsProvider>
            )}
        </MultipleUploadSt>
    );
}
