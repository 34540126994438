import { combineReducers } from 'redux';
import produce, { enableES5 } from 'immer';
import * as ActionConstants from '_dash/redux/constants';
// reducers
import { departments } from './departments';
import { companyGalleryReducer } from './companyGallery';
import { companyBenefitsReducer } from './companyBenefits';
import { companyCultureReducer } from './companyCulture';
import { companyAlbumsReducer } from './companyAlbums';
import { companyJobsReducer } from './companyJobs';
import { companyUsersReducer } from './companyUsers';
import subsidiariesReducer from './subsidiaries';
import { employerAutoMessages } from './employerAutoMessages';
import { companyFutureRolesReducer } from './companyFutureRoles';
import cndReducer from './cnd';
import cvReducer from '_dash/redux/reducers/cv';
// import hierarchyUsersReducer from './hierarchyUsers';

// import employerSaga from '../sagas/employer';
enableES5(); //before any logic
const initialState = {
    isFetching: true,
};

const companyDataKeys = [
    'facebook',
    'twitter',
    'linkedin',
    'linkedinWidgetId',
    'youtube',
    'instagram',
    'name',
    'address',
    'place_id',
    'industryId',
    'industryName',
    'size',
    'yearFounded',
    'phone',
    'email',
    'website',
    'intro',
    'about',
    'x',
    'y',
    'profileItems',
    'isPublished',
    'equalOpportunity',
    // 'defaultCurrency',
    // 'customJobTypesList',
    // 'customJobTypesStatus',
    'jobFunctions',
    'jobDepartments',
    'socialPostImage',
    'socialPostImageSubUse',
];

export const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionConstants.RECEIVE_COMPANY: {
            action.payload.isFetching = false;
            if (action.payload.profileItems !== undefined) {
                action.payload.profileItems = action.payload.profileItems.length > 0 ? action.payload.profileItems.split(',') : [];
            }
            return Object.assign({}, state, action.payload);
        }
        case ActionConstants.UPDATE_COMPANY_BY_KEY: {
            const newState = Object.assign({}, state, action.payload);
            return newState;
        }
        case ActionConstants.UPDATE_COMPANY: {
            const newState = Object.assign({}, state, doUpdateCompany(action.payload));
            return newState;
        }

        case ActionConstants.UPDATE_COMPANY_JBSETTINGS: {
            const jbSettings = Object.assign({}, state.jbSettings);
            jbSettings[action.jbKey] = action.payload;
            return Object.assign({}, state, { jbSettings: jbSettings });
        }

        case ActionConstants.UPDATE_COMPANY_SETTINGS: {
            const oldSettings = state.settings !== undefined ? Object.assign({}, state.settings) : {};
            const newSettings = Object.assign({}, oldSettings, action.payload);
            return Object.assign({}, state, { settings: newSettings });
        }

        case 'UPDATE_COMPANY_SOCIAL_INTEGRATION_FACEBOOK': {
            const socialIntegration = Object.assign({}, state.socialIntegration, { facebook: action.payload });
            return Object.assign({}, state, { socialIntegration });
        }
        case 'UPDATE_COMPANY_SOCIAL_INTEGRATION_TWITTER': {
            const socialIntegration = Object.assign({}, state.socialIntegration, { twitter: action.payload });
            return Object.assign({}, state, { socialIntegration });
        }

        case 'RECEIVE_COMPANY_URL': {
            return state.url ? state : Object.assign({}, state, action.payload);
        }

        case ActionConstants.UPLOAD_BANNER: {
            const newState = Object.assign({}, state, {
                bannerUrl: action.payload.src,
            });
            return newState;
        }

        case ActionConstants.UPLOAD_LOGO: {
            const newState = Object.assign({}, state, {
                logoUrl: action.payload.src,
                logoUrlThumb: action.payload.thumb,
            });
            return newState;
        }

        case ActionConstants.COMPANY_REFRESH_REJECTREASONS: {
            return Object.assign({}, state, {rejectReasons: action.payload});
        }

        case ActionConstants.COMPANY_APPEND_REJECTREASON: {
            const rejectReasons = state.rejectReasons.slice();
            rejectReasons.push(action.payload);
            return Object.assign({}, state, {rejectReasons});
        }

        case ActionConstants.COMPANY_REMOVE_REJECTREASON: {
            const rejectReasons = state.rejectReasons.toSpliced(action.payload.deleteIndex, 1);
            return Object.assign({}, state, {rejectReasons});
        }

        case ActionConstants.COMPANY_UPDATE_REJECTREASON: {
            const rejectReasons = state.rejectReasons.slice();
            rejectReasons[action.payload.updateIndex] = action.payload.updatedItem;
            return Object.assign({}, state, {rejectReasons});
        }

        default:
            return state;
    }
};

// private
const doUpdateCompany = (payload) => {
    let result = {};
    companyDataKeys.forEach((item) => {
        // maybe could have used Array.every()
        if (typeof payload[item] !== 'undefined') {
            switch (item) {
                case 'jobDepartments':
                case 'jobFunctions':
                case 'customCategories':
                    result[item] = payload[item].slice();
                    break;

                case 'profileItems': {
                    result[item] = payload[item].length > 0 ? payload[item].split(',') : [];
                    break;
                }

                default: {
                    result[item] = payload[item];
                    break;
                }
            }
        }
    });
    return result;
};

export const incentivesReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionConstants.RECEIVE_COMPANY_INCENTIVES:
            return action.payload;
        default:
            return state;
    }
};

export const pathsReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionConstants.RECEIVE_PATHS:
            return action.payload;
        default:
            return state;
    }
};

export const conversationsReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionConstants.RECEIVE_CONVERSATIONS:
            return action.payload;

        case ActionConstants.UPDATE_CONVERSATIONS:
            return Object.assign({}, action.payload);

        default:
            return state;
    }
};

export const messageCountsReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionConstants.RECEIVE_MESSAGECOUNTS:
            return action.payload;

        case ActionConstants.UPDATE_MESSAGECOUNTS:
            return Object.assign({}, action.payload);

        default:
            return state;
    }
};

export const navDataReducer = (state = [], action) => {
    switch (action.type) {
        case ActionConstants.RECEIVE_NAVDATA:
            return action.payload;

        default:
            return state;
    }
};

const userRolesReducer = (state = [], action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case 'COMPANY_RECEIVE_USER_ROLES':
                Object.assign(draft, action['payload']);
                break;
            // no default
        }
    });

export const userDataReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionConstants.RECEIVE_USERDATA:
            return action.payload;
        case ActionConstants.UPDATE_USERDATA:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
};

/* export const cvReducer = (
	state = {
		isWizardForm: 1
	},
	action
) => {
	switch (action.type) {
		case 'CV_END_WIZARD':
			return action.payload;
		default:
			return state;
	}
}; */

// const companyUserRoles = ( state = {}, action ) => {
// 	switch (action.type) {
// 		case ActionConstants.RECEIVE_COMPANY_USER_ROLES:
// 			return action.payload;
// 		default:
// 			return state;
// 	}
// };

const frontMenuReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionConstants.FRONT_MENU_RECEIVE:
            return action.payload;
        default:
            return state;
    }
};

const countriesReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionConstants.RECEIVE_COUNTRIES:
            return action.payload;
        default:
            return state;
    }
};
const isSubsidiary = (state = false) => state;

const permissionsReducer = (state = [], action) => {
    switch (action.type) {
        case 'COMPANY_RECEIVE_PERMISSIONS':
            return action.payload;
        default:
            return state;
    }
};
const isCustomRoles = (state = false, action) => {
    return state;
};
const companySubdomain = (state = false, action) => {
    return state;
};

/* const companyFetchJobsUsersReducer = ( state = {isStart:true, isDone:false}, action ) => {
	switch (action.type) {
		case "COMPANY_JOBS_USERS_RECEIVED":
		window.console.log('asdsd');
			return Object.assign({},state,{isDone:true});
		case "COMPANY_JOBS_USERS_REQUEST":
			return Object.assign({},state,{isStart:false});
		default:
			return state;
	}
}; */
/* const companyFetchJobsUsersReducer = (
	state = {
		isStart: true,
		isDone: false
	},
	action
) =>
	produce((state, draft) => {
		switch (action.type) {
			case 'COMPANY_JOBS_USERS_RECEIVED':
				draft.isDone = true;
				break;
			case 'COMPANY_JOBS_USERS_REQUEST':
				draft.isStart = false;
				break;
		}
	}); */

const rootReducer = combineReducers({
    paths: pathsReducer,
    navData: navDataReducer,
    userData: userDataReducer,
    conversations: conversationsReducer,
    messageCounts: messageCountsReducer,
    frontMenu: frontMenuReducer,
    departments,
    incentives: incentivesReducer,
    companyCulture: companyCultureReducer,
    company: companyReducer,
    companyBenefits: companyBenefitsReducer,
    companyGallery: companyGalleryReducer,
    companyAlbums: companyAlbumsReducer,
    companyFutureRoles: companyFutureRolesReducer,
    companyJobs: companyJobsReducer,
    subsidiaries: subsidiariesReducer,
    isSubsidiary,
    countries: countriesReducer,
    roles: userRolesReducer,
    autoMessages: employerAutoMessages,
    users: companyUsersReducer,
    permissions: permissionsReducer,
    isCustomRoles,
    companySubdomain,
    cv: cvReducer,
    appData: (state = {}, action) => state,
    cnd: cndReducer,
    // hierarchy entries: only one-time read for now
    hierarchyItems: ((state = {}) => state),
    accessSubsidiaryIds: ((state = {}) => state),
    fullHierarchy:((state = {}) => state),
    hierarchyUsers: ((state = []) => state ?? [])
});

export default rootReducer;
