// will be used in candidate flow, profile, ...
import produce from 'immer';

const cnd = (state = { profile: { activeTab: 'cv' }, modalMsg: {} }, action) =>
    produce(state, (draft) => {
        // eslint-disable-next-line default-case
        switch (action.type) {
            // cases for profile key
            case 'CANDIDATE_PROFILE_OPEN':
                Object.assign(draft.profile, action.payload);
                draft.profile.activeTab = 'cv';
                break;
            case 'CANDIDATE_PROFILE_CLOSE':
                if (!draft.profile.isFlow) {
                    draft.flow = undefined;
                }
                // empty draft.profile
                for (const key in draft.profile) delete draft.profile[key];
                draft.profile.activeTab = 'cv';
                if (state.profile.path === '/dash/crm/') draft.profile.reloadCRM = true;
                break;
            case 'CANDIDATE_PROFILE_INIT':
                Object.assign(draft.profile, action.payload.profile);
                // if come from cndFlow <TableFetch/>
                if (action.payload.flow) {
                    // draft.flow = action.payload.flow;
                    if (!draft.flow) draft.flow = {};
                    for (let flowKey in action.payload.flow) {
                        draft.flow[flowKey] = action.payload.flow[flowKey];
                    }
                }
                // if come from cndFlow <Table/> just mark as viewed if not already
                else if (draft.profile.isFlow && draft.flow && draft.flow.applicants) {
                    const index = draft.flow.applicants.findIndex((i) => i.candidateId === action.payload.profile.id);
                    if (index !== -1) {
                        draft.flow.applicants[index].viewStatus = 'viewed';
                    }
                }
                if (action.payload.profile.applyData?.isUploadCV === -1) {
                    draft.profile.activeTab = 'question';
                    // also update profile.cv if we have question with uploadCV type
                    action.payload.profile.questions?.some((q) => {
                        if (q.type === 'uploadCV') {
                            Object.assign(draft.profile.cv, {
                                isRezoomoCV: false,
                                fileUrl: q.answers[0].file,
                                isPdf: Boolean(q.answers[0].fileType === 'application/pdf'),
                                hasApplicationFormCV: true,
                            });
                            return true;
                        }
                        return false;
                    });
                }
                break;
            case 'CANDIDATE_PROFILE_UPDATE_KEY':
                draft.profile[action.payload.key] = action.payload.data;
                break;
            case 'CANDIDATE_PROFILE_UPDATE_CV_FILE_URL':
                draft.profile.cv.fileUrl = action.payload.fileUrl;
                draft.profile.cv.isPdf = true;
                break;
            case 'CANDIDATE_PROFILE_CHANGE_TAB':
                draft.profile.activeTab = action.payload.tab;
                break;
            case 'CANDIDATE_PROFILE_NOTES_DELETE':
                draft.profile.notes = draft.profile.notes.filter((n) => n.candidateNoteId !== action.payload.id);
                break;
            // cases for flow key
            case 'CANDIDATE_FLOW_INIT':
                Object.assign(draft, action.payload);
                break;
            case 'UPDATE_SELECTED_APPLICANTS':
                action.payload.forEach((e) => {
                    // check if is cndFlow redux
                    if (draft.flow.job) draft.flow.applicants[draft.flow.applicants.findIndex((i) => i.id === e.id)] = e;
                    // check if profile is open, in this case will be just one record because can't be multiple select
                    if (draft.profile.id) draft.profile.applyData = e;
                });
                draft.modalMsg.open = false;
                draft.modalMsg.data = {};
                break;
            case 'UPDATE_JOB_INTERVIEW_STEPS':
                draft.flow.job.interviewSteps = action.payload;
                break;
            case 'CANDIDATE_FLOW_CLOSE':
                draft.flow = undefined;
                break;

            //cases for modalMsg key
            case 'CANDIDATE_MODAL_MSG_OPEN':
                draft.modalMsg.open = true;
                draft.modalMsg.data = action.payload;
                break;
            case 'CANDIDATE_PROFILE_MSG_INIT': // the case for inline version and not the modal one
                draft.modalMsg.data = action.payload;
                break;
            case 'CANDIDATE_MODAL_MSG_CLOSE':
                draft.modalMsg.open = false;
                // when is in candidate profile need to update some parts (ex: convId, timeline)
                if (action.payload && action.payload.profile) Object.assign(draft.profile, action.payload.profile);
                break;
        }
    });
export default cnd;
